import "../PagesCss/Default.css";
import React, { Profiler, useState, useEffect } from "react";
import { useNavigate } from "react-router";

const LandingPage = (props) => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <>
      <div id="layoutSidenav">
        <div id="layoutSidenav_content">
          <main>
            <header
              className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
              id="dashMobile2"
            >
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title" />
                      <div className="page-header-icon">
                        <h1 className="page-header-title">
                          Progressive Estate Solutions&nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        </h1>
                      </div>
                      <br></br>
                      <div
                        className="page-header-subtitle"
                        style={{ color: "white" }}
                      >
                        Being appointed someone’s Executor is viewed as an
                        honour; however ...
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="page-header-subtitle">
                    <p> 1) It is a significant responsibility </p>
                    <p> 2) Most people are not sure where to start </p>
                    <p> 3) Time-sensitive tasks are a concern </p>
                    <p>
                      {" "}
                      4) there are legal and financial implications if mistakes
                      are made{" "}
                    </p>
                  </div>
                </div>
              </div>
            </header>
            <>
              <div className="container-xl px-4 mt-n10">
                <div className="row">
                  <div className="solid">
                    <div className="card mb-4">
                      <div className="card-header" id="leftspace">
                        Introduction to Progressive Estate Solutions
                      </div>
                      <div className="card-body" id="landingPageCardBody">
                        Since 2004 Progressive Estate Solutions has provided
                        innovative estate-related services for the Deathcare and
                        Financial services industries. These services allow
                        businesses to focus on their core competencies, while
                        offering enhanced services to their clients in a cost
                        effective manner.
                        <br />
                        <br />
                        Our solutions help consumers navigate tasks that can be
                        confusing, challenging and emotional – whether settling
                        the estate of a loved one or doing their own estate
                        planning.Our flagship offerings, Estate Fraud Protection
                        and Executor’s Aide, save consumers time and reduce
                        administrative burdens of being an Executor/Executrix.
                        <br></br>
                        <br />
                        <strong>
                          Please note that our solutions are only available
                          through Service Providers. For further information, or
                          to arrange a demonstration, please contact us at:
                          &nbsp;&nbsp;{" "}
                          <u style={{ color: "#0061f2" }}>
                            info@ProgressiveEstateSolutions.com
                          </u>
                        </strong>
                        <br></br>
                        <hr />
                        <a
                          className="mobClickHere"
                          style={{
                            color: "#0061f2",
                            marginLeft: "28%",
                            margin: "20px",
                            cursor: "pointer",
                          }}
                          href="/login"
                        >
                          Click Here for Service Provider Login Page
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </main>
          <footer className="footer-admin mt-auto footer-light footerMobile">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">
                  Progressive Estate Solutions Inc. {currentYear}
                </div>
                <a
                  className="col-md-6 text-md-end small"
                  onClick={() => {
                    navigate("/PrivacyStatement");
                  }}
                >
                  Privacy Statement
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
export default LandingPage;
