// Layout.js

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const pageTitle = getPageTitle(path); // Get title based on path
    document.title = pageTitle;
  }, [location]);

  const getPageTitle = (path) => {
    if (path.startsWith("/EditUser/")) {
      const id = path.substring("/EditUser/".length); // Extract id from path
      return `Edit User`;
    }
    if (path.startsWith("/EditNail/")) {
      const id = path.substring("/EditNail/".length); // Extract id from path
      return `Edit Nail`;
    }
    if (path.startsWith("/EditMaterials/")) {
      const id = path.substring("/EditMaterials/".length);
      return `Edit Materials`;
    }
    if (path.startsWith("/EditTile/")) {
      const id = path.substring("/EditTile/".length);
      return `Edit Tile`;
    }
    switch (path) {
      case "/login":
        return "Login";
      case "/home":
        return "Home";
      case "/landingpage":
        return "landingpage";
      case "/register":
        return "Register";
      case "/dignityInput":
        return "Pesdata";

      case path.startsWith("/EditUser/"):
        return "Edit User";

      default:
        return "PES ";
    }
  };

  return <div>{children}</div>;
};

export default Layout;
